@charset 'UTF-8';

.inner-wrap {
    &.move--left {
        .search-btns {
            display: none;
        }

        .header-list__right {
            position: relative;
            z-index: 6;
            padding-right: 0 !important;
            // the negative margin-right is used
            // to prevent the icons in the header from
            // moving to the left when the RHS is open
            //
            // the below z-index is used to ensure the icons
            // stays on the top and don't get hidden by the
            // search's input block
            float: right;
        }
    }

    &.move--right {
        .search-bar__container {
            display: none;
        }

        .search-btns {
            display: none;
        }

        .header-list__right {
            position: relative;
            z-index: 6;
            padding-right: 0 !important;
            margin-right: -18px;
            // the negative margin-right is used
            // to prevent the icons in the header from
            // moving to the left when the RHS is open
            //
            // the below z-index is used to ensure the icons
            // stays on the top and don't get hidden by the
            // search's input block
            float: right;
        }
    }
}

.app__content {
    @include display-flex;
    @include flex-direction(column);

    position: relative;
    height: 100%;
    padding-top: 50px;
    margin-left: 220px;
    background: $white;

    .channel__wrap & {
        padding-top: 0;
    }
}

#archive-link-home {
    @include flex(0 0 auto);

    cursor: pointer;
    font-size: 13px;

    .fa {
        @include opacity(0.7);

        font-size: 11px;
    }

    a {
        display: block;
        width: 100%;
        padding: 10px 20px;
        color: inherit;
    }
}

.delete-message-text {
    margin-top: 10px;
}

.col-sm-auto {
    padding-right: 15px;
    padding-left: 15px;
}
