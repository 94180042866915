@charset 'UTF-8';

.tip-backdrop {
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: alpha-color($black, 0.5);
}

.tip-overlay {
    @include border-radius(3px);

    position: absolute;
    z-index: 1000;
    width: 350px;
    max-width: 90%;
    padding: 20px;
    background-color: $white;

    .arrow {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-width: 10px;
        border-style: solid;
        border-color: transparent;
    }

    &.tip-overlay--sidebar {
        max-width: 75%;
        min-height: 330px;
        margin: 50px 0 0 10px;

        .tutorial__footer {
            position: absolute;
            bottom: 20px;
            left: 0;
            width: 100%;
            padding: 0 20px;
        }

        .arrow {
            top: 80px;
            left: -10px;
            border-right-color: $white;
            border-left-width: 0;
            margin-top: -10px;
        }
    }

    &.tip-overlay--header--left {
        margin: 10px 0 0 10px;

        .arrow {
            top: 15px;
            left: -10px;
            border-right-color: $white;
            border-left-width: 0;
        }
    }

    &.tip-overlay--header--bottom {
        margin-top: -10px;

        .arrow {
            top: -10px;
            left: 50%;
            border-top-width: 0;
            border-bottom-color: $white;
            margin-left: -10px;
        }
    }

    &.tip-overlay--chat {
        margin-top: -10px;

        .arrow {
            bottom: -10px;
            left: 50%;
            border-top-color: $white;
            border-bottom-width: 0;
            margin-left: -10px;
        }
    }

    h4 {
        margin: 5px 0 15px;
        font-size: em(16px);
        font-weight: 600;
    }

    p {
        font-size: 13px;
        line-height: 1.6;

        strong {
            font-weight: 600;
        }
    }

    .btn {
        @include border-radius(3px);

        border: none;
        margin: 10px 0;
        background: alpha-color($black, 0.3);
        color: $white;

        &:hover,
        &:active,
        &:focus {
            border: none;
            background: alpha-color($black, 0.4);
            color: $white;
        }
    }

    .tip-opt {
        font-size: 12px;

        span {
            @include opacity(0.9);
        }
    }

    .tutorial__circles {
        margin: 1.5em 0 -1.7em -4px;

        .circle {
            width: 7px;
            height: 7px;
            margin: 0 4px;
        }
    }
}

.tip-button {
    position: relative;
    z-index: 998;
    top: -10px;
    right: -10px;
    cursor: pointer;
}

.tip-div {
    position: absolute;
    top: 0;
    right: 0;

    &.tip-overlay--header {
        top: 21px;
        right: 2px;

        .tip-button {
            @include opacity(0.8);
        }
    }

    &.tip-overlay--sidebar {
        @include opacity(0.8);

        top: 8px;
        right: 21px;
    }
}

.tutorial-steps__container {
    display: flex;
    overflow: auto;
    height: 100vh;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .tutorial__content {
        .tutorial__steps {
            position: relative;
            display: inline-block;
            max-width: 310px;
            min-height: 480px;
            margin-bottom: 50px;
            text-align: left;
        }
    }

    .tutorial__footer {
        position: absolute;
        bottom: 0;
    }

    h1 {
        margin: -20px 0 30px;
        font-size: em(40px);
        font-weight: 600;
    }

    h3 {
        margin-bottom: 30px;
        font-size: em(24px);
        font-weight: 600;
    }

    .tutorial__circles {
        position: absolute;
        bottom: 25px;
    }

    .tutorial-skip {
        margin-left: 13px;
    }
}

.tutorial__circles {
    margin: 2em 0;

    .circle {
        @include border-radius(9px);
        @include opacity(0.2);

        display: inline-block;
        width: 9px;
        height: 9px;
        margin: 0 5px;
        background: $black;

        &.active {
            @include opacity(1);

            background: $primary-color;
        }
    }
}

.tutorial__app-icons {
    margin: 2em 0;
}
